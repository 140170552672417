.event_purchaser_row {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100px;
	background: #fff;
	margin-bottom: 10px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.select_user_div {
	display: flex;
	justify-content: center;
	align-items: center;
	position:relative;
	text-decoration: underline;
}
.programHover{
	display:flex;
	opacity: 0;
	background-color:#d91c24;
	color:white;
	position:absolute;
	justify-content: center;
	align-items: center;
	top:0;
	bottom:0;
	left:-50%;
	right:0;
	width:150%;
}
.programHover:hover{
	/* display:flex; */
	opacity: 1;
	display:flex;
	width:100%;
	left:0;
	transition: ease 500ms;
}
.select_user_div img {
	cursor: pointer;
}
.invisible-scrollbar::-webkit-scrollbar  {
	display: none;
}
.event_purchaser_row_col {
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-left: 2px solid #e0e0e0;
	color: #262262;
	overflow: scroll;
	position:relative;
}
.event_purchaser_row_col::-webkit-scrollbar {
	height: 7px;
	margin-right: 2px !important;
}
.event_purchaser_row_col::-webkit-scrollbar-track {
	background: none;
}
.event_purchaser_row_col::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.event_purchaser_row_col::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}
