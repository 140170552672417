@font-face {
	font-family: 'bold';
	src: url('./assets/fonts/Chivo-Bold.woff');
}
@font-face {
	font-family: 'regular';
	src: url('./assets/fonts/Chivo-Regular.woff');
}
@font-face {
	font-family: 'light';
	src: url('./assets/fonts/Chivo-Light.woff');
}

.bold {
	font-family: 'bold';
}
.regular {
	font-family: 'regular';
}
.light {
	font-family: 'light';
}

body,
html {
	height: 100% !important;
}
body {
	font-family: 'regular' !important;
	overflow-x: hidden;
}
.wrapper {
	max-width: 2560px;
	margin: auto;
	width: 100%;
}
.big-container {
	max-width: 1470px;
	margin: auto;
	padding-left: 35px;
	padding-right: 35px;
	width: 100%;
}

.white {
	color: #fff;
}
.black-color {
	color: #000;
}
.blue {
	color: #262262;
}
.red {
	color: #d91c24;
}

// ::selection {
	// background-color: #d91c24;
	// color: #fff;
// }

.show-1024 {
	display: none !important;
}

.red-bg {
	background: #d91c24;
}
.black-bg {
	background: #000;
}
.white-bg {
	background: #fff;
}
.blue-bg {
	background: #262262;
}
.red-bg {
	background: #d91c24;
}

.border-red {
	border: 1px solid #d91c24;
}

.font-11 {
	font-size: 11px;
}
.font-12 {
	font-size: 12px;
}
.font-13 {
	font-size: 13px;
}
.font-14 {
	font-size: 14px;
}
.font-17 {
	font-size: 17px;
}
.font-18 {
	font-size: 18px;
}
.font-16 {
	font-size: 16px;
}
.font-20 {
	font-size: 20px;
}
.font-21 {
	font-size: 21px;
}
.font-25 {
	font-size: 25px;
	line-height: 25px;
}
.font-24 {
	font-size: 24px;
	line-height: 24px;
}
.font-45 {
	font-size: 45px;
	line-height: 45px;
}

.letterspacing1 {
	letter-spacing: 1px;
}
.text-upper {
	text-transform: uppercase;
}
:focus {
	outline: none !important;
}
a {
	color: inherit;
}
a:hover {
	color: #d91c24 !important;
	text-decoration: none !important;
}
a:focus {
	outline: none !important;
	border: none !important;
	text-decoration: none !important;
}
.pointer {
	cursor: pointer;
}
.text-underline {
	text-decoration: underline !important;
}
.abs-anch {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: transparent !important;
	z-index: 0;
}
.borderbottom {
	border-bottom: 1px solid;
}
.list-inline li {
	display: inline-block;
}
.vertical-align-super {
	vertical-align: super;
}
.vertical-align-bottom {
	vertical-align: bottom;
}
.display-block {
	display: block;
}

.pt-120 {
	padding-top: 120px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pt-80 {
	padding-top: 80px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pt-40 {
	padding-top: 40px;
}
.pb-40 {
	padding-bottom: 40px;
}

.nomargin-top {
	margin-top: 0 !important;
}
.nomargin-bottom {
	margin-bottom: 0 !important;
}
.nomargin-left {
	margin-left: 0 !important;
}
.nomargin-right {
	margin-right: 0 !important;
}
.nopadding-top {
	padding-top: 0 !important;
}
.nopadding-bottom {
	padding-bottom: 0 !important;
}
.nopadding-left {
	padding-left: 0 !important;
}
.nopadding-right {
	padding-right: 0 !important;
}

.margin-top-40 {
	margin-top: 40px;
}

.display-table {
	display: table;
	width: 100%;
	table-layout: fixed;
	height: 100%;
	margin: auto;
}
.display-table .display-cell {
	display: table-cell;
	vertical-align: middle;
}
.display-table .display-cell.v-top {
	vertical-align: top;
}
.display-table .display-cell.v-bottom {
	vertical-align: bottom;
}

input:focus::-webkit-input-placeholder {
	color: #fff;
}
input:focus:-moz-placeholder {
	color: #fff;
}
input:focus::-moz-placeholder {
	color: #fff;
}
input:focus:-ms-input-placeholder {
	color: #fff;
}
textarea:focus::-webkit-input-placeholder {
	color: #fff;
}
textarea:focus:-moz-placeholder {
	color: #fff;
}
textarea:focus::-moz-placeholder {
	color: #fff;
}
textarea:focus:-ms-input-placeholder {
	color: #fff;
}

input::-webkit-input-placeholder {
	font-family: 'regular';
	color: #fff;
}
input:-moz-placeholder {
	font-family: 'regular';
	color: #fff;
}
input::-moz-placeholder {
	font-family: 'regular';
	color: #fff;
}
input:-ms-input-placeholder {
	font-family: 'regular';
	color: #fff;
}
textarea::-webkit-input-placeholder {
	font-family: 'regular';
	color: #fff;
}
textarea:-moz-placeholder {
	font-family: 'regular';
	color: #fff;
}
textarea::-moz-placeholder {
	font-family: 'regular';
	color: #fff;
}
textarea:-ms-input-placeholder {
	font-family: 'regular';
	color: #fff;
}

select {
	-webkit-appearance: none;
	position: relative;
	// background-image: url("./assets/images/select-arrow.svg");
	background-position: right 15px center !important;
	background-repeat: no-repeat !important;
}

input,
select,
texarea {
	border-radius: 0 !important;
}
strong,
b {
	font-weight: 400 !important;
	font-family: bold;
}

input[type='search']::-webkit-input-placeholder {
	color: #fff;
}
input[type='search']:-moz-placeholder {
	color: #fff;
}
input[type='search']::-moz-placeholder {
	color: #fff;
}
input[type='search']:-ms-input-placeholder {
	color: #fff;
}

.header {
	position: fixed;
	width: 100%;
	max-width: 2560px;
	margin: auto;
	left: 0;
	right: 0;
	transition: all ease-in-out 0.3s;
	background: #dedeea;
	padding: 0px 15px;
	z-index: 9;
}

.main-content {
	padding: 110px 30px 60px 275px;
}

.left-panel {
	position: fixed;
	width: 256px;
	top: 0;
	height: 100%;
	z-index: 10;
	padding-top: 75px;
	overflow:auto;
}

.left-panel::-webkit-scrollbar {
    display: none;
}

.panel-menu ul {
	padding-left: 15px;
}

// .panel-menu ul li {
// 	padding: 25px 0 25px 15px;
// 	position: relative;
// }
.list-unstyled{
	display:flex;
	flex-direction: column;
	
}
.menu_item{
	padding: 25px 0 25px 15px;
	position: relative;
	font-size: 15px;
	color: #fff;
	font-family: 'bold';
	text-transform: uppercase;
}
.menu_item_active svg,
.menu_item_active svg path
// .menu_item_active svg g rect
{
	fill: #d91c24 !important;
}

.menu_item_active:after{
	content: '';
	width: 5px;
	height: 50px;
	background: #d91c24;
	position: absolute;
	left: -15px;
	top: 0;
	bottom: 0;
	margin: auto;
}

// .panel-menu ul li.active svg,
// .panel-menu ul li.active svg path {
// 	fill: #d91c24 !important;
// }
// .panel-menu ul li.active:after {
// 	content: '';
// 	width: 5px;
// 	height: 50px;
// 	background: #d91c24;
// 	position: absolute;
// 	left: -15px;
// 	top: 0;
// 	bottom: 0;
// 	margin: auto;
// }
span.menu-icon svg {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.logo img {
	width: 200px;
	margin: 0 auto 45px;
	display: block;
}

.search-div input[type='submit'] {
	position: absolute;
	left: 0;
	background: url('./assets/images/Search.svg') no-repeat left center;
	color: transparent;
	border: none;
	top: 25px;
	width: 20px;
	cursor: pointer;
	height: 30px;
	background-size: 100%;
}

.user-profile {
	display: flex;
	align-items: center;
	height: 50px;
}

.search-div {
	border-right: 2px solid rgba(255, 255, 255, 0.45);
	margin-right: 35px;
	padding: 0 35px;
	height: 80px;
	border-left: 2px solid rgba(255, 255, 255, 0.45);
}

.user__profile img {
	width: 60px;
	height: 60px;
	border-radius: 100%;
}
.Header_Menu_Container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
input[type='search'] {
	background: transparent;
	border: none;
	color: #031083;
	padding: 0px 0px 0px 45px;
	line-height: 80px;
}

/*=======================MEDIA QUERIES=======================*/

@media (max-width: 1680px) {
}
@media (max-width: 1600px) {
}
@media (max-width: 1550px) {
	.logo img {
		width: 150px;
	}
	.left-panel {
		width: 215px;
	}
	.main-content {
		padding: 110px 30px 60px 235px;
	}
}
@media (max-width: 1366px) {
}
@media (max-width: 1199px) {
}
@media (max-width: 1024px) {
	.show-1024 {
		display: block !important;
	}
	.hide-1024 {
		display: none !important;
	}
	.left-panel.hide-1024,
	.left-panel.hide-1024 *,
	.left-panel.hide-1024 *::after,
	.left-panel.hide-1024 *::before,
	.left-panel.hide-1024 *,
	.left-panel.hide-1024 *:after,
	.left-panel.hide-1024 *:before {
		display: block !important;
		width: 0 !important;
		padding: 0;
		margin: 0;
		color: transparent;
		overflow: hidden;
		animation: all ease 0.3s;
	}
	.left-panel,
	.left-panel *,
	.left-panel *::after,
	.left-panel *::before,
	.left-panel *,
	.left-panel *:after,
	.left-panel *:before {
		animation: all ease 0.3s;
	}
	.recently-added .col-md-3.col-sm-12 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 30px;
	}
	.user__profile img {
		width: 40px;
		height: 40px;
	}
	.search-div,
	.user-profile {
		height: 50px;
	}
	input[type='search'] {
		line-height: 50px;
	}
	.main-content {
		padding: 70px 30px 60px 30px;
	}
	.panel-menu ul li {
		padding: 20px 0 20px 15px;
	}
	.search-div input[type='submit'] {
		top: 11px;
	}
	.header .container-fluid {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
	}
	.header .logo img {
		width: 90px;
		margin: 0;
	}
	.search-div {
		margin-right: 15px;
		padding: 0 15px;
	}
	a.rwd-search img {
		width: 20px;
		margin-right: 15px;
	}
	.hamburger-menu {
		cursor: pointer;
	}
	.hamburger-menu span {
		width: 35px;
		height: 3px;
		display: block;
		background: #262262;
		margin: 5px 0px 5px 10px;
	}
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
	.header {
		padding: 0px;
	}
	.main-content {
		padding: 70px 0px 60px 0px;
	}
}
@media (max-width: 600px) {
	.recently-added .col-md-3.col-sm-12 {
		margin-bottom: 10px;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.recently-added .post_details {
		flex-direction: column;
	}
	.recently-added .post_details > div {
		width: 100% !important;
	}
	.post_time.font-11 {
		position: static;
		padding: 10px 0;
	}
	.recently-added .post__image {
		width: 100%;
		height: auto;
	}
}
@media (max-width: 480px) {
}
@media (max-width: 375px) {
}

@media (max-width: 320px) {
}
