
.auth-layout {
  .font-36{
  font-size:36px;
  }
  .font-14{
  font-size:14px;
  }
  .font-16{
  font-size:16px;
  }

  .margin-top-20{
  margin-top:20px;
  }

  .padding-top-10{
  padding-top:10px;
  }

  .test {
    background: #262262 url("../../../assets/images/balloon.svg") no-repeat 0% 100%;
    height: 100vh;
    position: fixed;
    width: 100%;
    border-top: 4px solid #D91C24;
  }
  .main-content {
    padding: 0px 30px 0px 30px;
    width: 100%;
  }
  .wrapper {
    max-width: inherit;
    margin: auto;
    width: 100%;
     .test{
      display: flex;
      align-items: center;
     }
  }
}


@media (max-width: 1230px) {
  // .auth-layout .main-content {
  //   padding: 81px 30px 30px 45px !important;
  // }
}
@media (max-width:1024px) {
  .auth-layout .test {
    height: unset;
    min-height: 100vh;
    position: unset;
  }
}
@media (max-width: 580px) {
  .auth-layout .main-content {
    padding: 35px 30px 30px 30px !important;
  }
}
