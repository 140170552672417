.coach_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}

.coach_form_div {
	height: 100%;
	display: flex;
	flex-direction: row;
}
.seach_bar {
	background: #514c91;
	white-space:nowrap;
}
.search_cancel{
	text-decoration: underline;
	color:red;
	text-align: center;
	font-weight: bold;
}
.search_button {
	height: 100% !important;
	width: 50px !important;
	border: none !important;
	cursor: pointer !important;
	background: none !important;
	background: url('../../../assets/images/Search-white.svg') no-repeat center !important;
}
.search_button::placeholder {
	color: transparent !important;
}
.search_input,
.search_input:focus {
	color: #fff;
	outline: none;
	height: 100%;
	background: none;
	border: none;
}
.coach_add_new {
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 120px;
	color: #fff;
	background: #262262;
}
.coach_list_div {
	width: 100%;
	height: 60%;
	padding: 20px;
}
.coach_list_top {
	width: 100%;
	height: 50px;
	display: flex;
	flex-direction: row;
	background: #262262;
}
.coach_heading_table_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
}
.coach_table_header {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: #fff;
	font-weight: bold;
	border-left: 1px solid #514c91;
}
.photo_head,
.name_head,
.last_head {
	width: 12%;
}
.detail_head {
	width: 26%;
}
.event_head {
	width: 26%;
}
.view_head {
	width: 12%;
}
.coach_list_pagination {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.coachList_pagination {
	font-size: 1.2rem;
	font-weight: bold;
	padding: 10px;
	color: #262262;
	cursor: pointer;
}
.coach_list_active_page {
	color: #d91c24;
}
///////////////// MEDIA PART /////////////////////

@media (max-width: 620px) {
	.coach_top_div {
		flex-direction: column;
	}
}
@media (max-width: 410px) {
	.coach_top_div {
		padding: 0px;
	}
	.coach_form_div {
		flex-direction: column;
		align-items: flex-end;
	}
	.coach_add_new {
		margin-left: 0px;
		padding: 5px;
	}
}
