.events_row_table {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 120px;
	background: #fff;
	border-bottom: 1px solid #e0e0e0ab;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.events_row_row_canceled{
	background-color: lightgrey;
}
.events_row_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
}
.events_row_row .events_row_col {
	padding-left: 10px;
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 2px solid #e0e0e0;
	overflow: auto;
	text-transform: uppercase;
}
.events_row_col::-webkit-scrollbar {
	width: 7px;
	margin-right: 2px !important;
}
.events_row_col::-webkit-scrollbar-track {
	background: none;
}
.events_row_col::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.events_row_col::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.events_row_in::-webkit-scrollbar {
	width: 7px;
	margin-right: 2px !important;
}
.events_row_in::-webkit-scrollbar-track {
	background: none;
}
.events_row_in::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.events_row_in::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.event_date_col {
	display: flex !important;
	flex-direction: column;
	justify-content: center !important;
	align-items: flex-start !important;
}
.event_detail_link {
	color: #262262;
}
.events_edit_link {
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 2px solid #e0e0e0;
	overflow: auto;
	height: 100%;
	justify-content: center;
}
.arrow_div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	transition: all 0.3s;
}
.arrow_div:hover {
	background: #d91c24;
}
