.event_detail_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}
.event_detail_mainbody {
	padding: 20px;
}
.event_detail_mainrow {
	width: 100%;
	min-height: 150px;
	max-height: 215px;
	background: #fff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px;
	margin: 20px 0px;
}
.event_detail_mainrow_col {
	display: flex;
	flex-direction: column;
	width: 17%;
	height: 100%;
	justify-content: flex-start;
}
.detail_total_event_sale {
	width: 100%;
	height: 50px;
	background: #f2f2f2;
	padding: 10px;
	flex-direction: row;
}
.button_main_row_col {
	display: flex;
	flex-direction: column;
	width: 28%;
	height: 100%;
	justify-content: center;
}

/* ///////////////DRAFT EVENT//////////////// */
.publish_Event_draft_btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	height: 50px;
	border: 2px solid #262262;
}
.publish_button_detail_div {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 80%;
	border-left: 2px solid #262262;
}
/* ///////////////PUBLISHED EVENT//////////////// */
.published_event_btn_div {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.upper_btn_div,
.lower_btn_div {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.edit_event_detail {
	padding: 0px 10px;
	margin-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	width: 120px;
	color: #fff;
	background: #262262;
}
.email_all_btn_detail {
	padding: 0px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	width: 170px;
	color: #fff;
	background: #d91c24;
	cursor: pointer;
}
.view_programs_btn {
	padding: 0px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 170px;
	color: #fff;
	background: #262262;
	cursor: pointer;
}
/* ///////////////EVENT TABLE//////////////// */
.events_detail_list_top {
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	background: #262262;
}
.events_detail_table_header {
	display: flex;
	align-items: center;
	height: 100%;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	border-left: 2px solid #514c91;
	text-transform: uppercase;
}
.wi-25 {
	width: 25%;
}
.wi-21 {
	width: 21%;
}
.wi-20 {
	width: 20%;
}
.wi-18 {
	width: 18%;
}
.wi-17 {
	width: 17%;
}
.wi-15 {
	width: 15%;
}
.wi-13 {
	width: 13%;
}

.program_half {
	display: flex;
	flex-direction: row;
	width: 70%;
}

.program_half2 {
	display: flex;
	flex-direction: row;
	width: 30%;
}

.event_program_mainrow_col {
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: 0px 20px;
	height: 100%;
	justify-content: flex-start;
}
.event_program_detail_mainrow_col {
	display: flex;
	flex-direction: column;
	width: 20%;
	margin: 0px 20px;
	height: 100%;
	max-height: 180px;
	justify-content: flex-start;
	overflow: auto;
}
.event_program_detail_mainrow_col::-webkit-scrollbar {
	height: 7px;
	margin-right: 2px !important;
}
.event_program_detail_mainrow_col::-webkit-scrollbar-track {
	background: none;
}
.event_program_detail_mainrow_col::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.event_program_detail_mainrow_col::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.event_program_detail_mainrow_col2 {
	display: flex;
	flex-direction: column;
	width: 40%;
	margin: 0px 20px;
	height: 100%;
	max-height: 180px;
	justify-content: flex-start;
	overflow: auto;
}
.event_program_detail_mainrow_col2::-webkit-scrollbar {
	height: 7px;
	margin-right: 2px !important;
}
.event_program_detail_mainrow_col2::-webkit-scrollbar-track {
	background: none;
}
.event_program_detail_mainrow_col2::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.event_program_detail_mainrow_col2::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}
