.profile_detail_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}
.left_detail_top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.edit_profile {
	margin-left: 15px;
	padding: 0px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	width: 120px;
	color: #fff;
	background: #262262;
}
.link_text_div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 100%;
	border-left: 1px solid #514c91;
}

//////////////// main body //////////////////
.profile_detail_body {
	padding:20px;
	display: flex;
	flex-direction: row;
	width: 70%;
}
.profile_detail_body .main_profile_info {
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.profile_detail_body .main_profile_info .profile_pfp {
	width: 200px;
	height: 200px;
}
.profile_detail_body .main_profile_info .profile_pfp img {
	width: 100%;
}
.profile_phone_num,
.profile_email,
.profile_address {
	color: #262262;
	display: flex;
	flex-direction: row;
}
.profile_phone_num img,
.profile_email img,
.profile_address img {
	margin-right: 10px;
}
