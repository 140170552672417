.master_search_container{
    position:relative;
    background-color: #fff;
    margin: 1rem;
    padding:.25rem .5rem;
    display:flex;
    align-items: center;
    justify-content: end;
}

.master_search_input{
    background-color: #fff;
    color:#262262;
    outline: none;
    border:none;
    margin-left:.5rem;
}

.master_search_input::placeholder{
    color:#262262;
}

.master_search_popup{
    box-shadow: 1px 4px 9px 0px darkgrey;
    transition: all 3s;
    top:100%;
    left:0;
    right:0;    
    position: absolute;
    background-color: #fff;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.master_search_popup_title{
    width:100%;
    background-color: #f0f0f0;
    color:#212529CA;
    font-size:10pt;
    font-weight: bold;
    padding:.25rem .5rem;
}
.master_search_popup_body{
    cursor: pointer;
    width:100%;
    padding:.5rem;
}
.master_search_popup_body_item{
    border-bottom:1px dotted #c2c2c2;
}
.master_search_popup_body_item:last-of-type{
    border-bottom: none;
}