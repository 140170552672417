.event_edit_detail_top_div {
  display: flex;
  height: 20%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid #dbdbdb;
}
.left_detail_top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.edit_event_save.disabledButton,
.edit_event_save.disabledButton {
  background: #e0e0e0;
  cursor: wait;
}
.edit_event_save {
  margin-left: 15px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 120px;
  color: #fff;
  background: #d91c24;
}
.edit_event_save:hover {
  color: #fff !important;
}
.edit_link_text_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
}
.edit_link_text_div:hover {
  color: #fff;
  cursor: pointer;
}
.edit_event_mainbody {
  padding: 20px;
}
.recurence_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recurence_choices {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event_recurence_radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.custom_recurence {
  padding: 10px;
  display: flex;
  align-items: center;
  background: #262262;
  cursor: pointer;
}
.custom_recurence img {
  width: 15px;
}
/* ////////////////FORM/////////////////// */
.event_edit_form_comtainer {
  padding: 20px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.event_edit_row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.event_edit_input {
  margin-bottom: 15px;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 32%;
  height: 100px;
  color: #262262;
}
.event_edit_input input {
  /* background-color: #fff !important; */
  font-size: 18px;
  color: #262262;
  width: 100%;
  border: none;
  padding: 20px;
}
.event_edit_input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #484391;
  font-size: small;
  opacity: 1; /* Firefox */
}
.event_edit_input input:focus {
  border-bottom: 2px solid #d91c24;
}
.double_col_event_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 32%;
}
.double_col_event_input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #262262;
  font-size: small;
  opacity: 1; /* Firefox */
}
.double_col_event_input input {
  background: #fff;
  font-size: 18px;
  color: #262262;
  width: 100%;
  border: none;
  padding: 20px;
}
.double_col_event_input input:focus {
  border-bottom: 2px solid #d91c24;
}
.double_col_event_input .half_row {
  margin-inline-end: 40px;
  margin-inline-start: 20px;
  width: 47%;
}
.edit_event_select {
  width: 80%;
  height: 70px;
  border: none;
  padding: 20px;
  color: #262262;
  font-size: 18px;
  cursor: pointer;
}
.edit_event_select:focus {
  border-bottom: 2px solid #d91c24;
}
.select_arrow_div {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.event_edit_banner_img {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.event_banner_image_container {
  position: relative;
  height: 200px;
  overflow: hidden;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event_banner_image_container .banner_image {
  width: 100%;
}

.event_video_container {
  position: relative;
  height: 370px;
  overflow: hidden;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event_video_container .event_video{
  width: 100%;
}
.remove_photo {
  width: 25px;
  position: absolute;
  top: 3%;
  right: 2%;
  cursor: pointer;
}
.event_add_photo_btn {
  margin-top: 20px;
  width: 260px;
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0px;
}
.event_custom_file_input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.event_add_photo_label {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: underline;
  width: 205px;
}
.event_add_photo_label img {
  margin-right: 8px;
}
.event_desc_div {
  width: 100%;
}
.big_event_input {
  height: 500px;
  overflow-y: auto;
}
.big_event_input:focus {
  border-bottom: 2px solid #d91c24;
}
.event_program_edit_section {
  display: flex;
  flex-direction: column;
}
.event_programs_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.add_event_program_btn {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #d91c24;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_event_program_btn img {
  width: 30px;
}
.edit_special_offecr_section {
  display: flex;
  flex-direction: column;
}
.offer_edit_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.edit_event_button_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.blue_edit_event_button {
  background: #262262;
  width: 180px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.blue_edit_event_button.disabledButton {
  background: #e0e0e0;
  cursor: wait;
}
.red_edit_event_button {
  background: #d91c24;
  width: 180px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.calImg {
  position: absolute;
  right: 8%;
  top: 50%;
}

.event_calendar {
  background-color: #fff !important;
}
.event_calendar input {
  background-color: #ffffff00 !important;
}

.error{
  color:red;
}

.grid_display_dates{
  width: 100%;
  display: grid; 
  grid-template-columns: auto auto auto;
}

.see_details_box
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  font-size: 18px;
  color: #262262;
  width: 100%;
  border: none;
  padding: 20px;
  box-sizing: unset;
}

.see_details_span
{
  text-decoration: underline;
  cursor: pointer;
}