.coach_new_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}
.left_new_top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.coach_new_body {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px;
}
.coach_pfp_div {
	display: flex;
	flex-direction: column;
}
.coach_new_pfp {
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin-bottom: 40px;
}
.coach_new_pfp img {
	border-radius: 50%;
	width: 100%;
}
.add_photo_btn {
	position: relative;
	border: none;
	background-color: transparent;
	padding: 0px;
}
.custom_file_input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.add_photo_label {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: underline;
}
.add_photo_label img {
	margin-right: 8px;
}

//form
.coach_new_form {
	width: 70%;
}
.new_form_row {
	display: flex;
	flex-direction: row;
}
.coach_new_input_div {
	margin-right: 20px;
	display: flex;
	flex-direction: column;
	width: 50%;
}
.coach_new_input {
	width: 100%;
	padding: 1rem !important;
	background-color: #fff !important;
	border: 1px solid #fff;
	margin: 0.5rem 0 1rem;
	color: #262262;
	font-weight: normal;
}
.coach_new_input:active,
.coach_new_input:focus {
	border-bottom: 1px solid #d91c24;
	outline: none;
	transition: ease 400ms;
}
.coach_new_input::placeholder {
	color: #26226260;
}
.coach_new_input + label {
	opacity: 0%;
	color: #262262;
	visibility: hidden;
}
.coach_new_input:focus + label {
	visibility: visible;
	opacity: 100%;
	transition: ease 500ms;
}

//number input
.number_input::-webkit-outer-spin-button,
.number_input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.number_input {
	-moz-appearance: textfield;
}

.edit_button_div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.new_save_red_button {
	width: 200px;
	border: none;
	background: #d91c24;
	padding: 15px 50px;
	margin-top: 20px;
	margin-right: 15px;
}
.new_save_red_button:disabled{
	background-color: #c8c8c8;
	cursor:not-allowed;
}
.cancel_blue_button {
	text-align: center;
	width: 200px;
	border: none;
	background: #262262;
	padding: 15px 50px;
	margin-top: 20px;
}
// .coach_new_date{
// 	width: 100%;
// 	height: 72px;
// 	position: relative;
// }
.react-datepicker-wrapper{
	width: 100%;
}
.coach_new_date input{
	width: 100%;
	cursor: pointer;
	// height: 72px;
	border: none;
	// font-size: 16px;
	// color: #262262;
	// padding: 20px;
	// background-color: #f2f2f2;
}
.coach_new_date input{
	background:transparent !important
}

.error{
	color: red;
}