.member_delete_btn {
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 120px;
	background: #d91c24;
	cursor: pointer;
}
.go_back_img{
	padding-right: 10px;
}
.member_delete_btn_disable {
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 120px;
	background: gray;
	cursor: pointer;
}

.memberlist_export_btn{
	color: #fff;
	background: #262262;
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 120px;
	cursor: pointer;
}

.search_cancel{
	text-decoration: underline;
	color:red;
	text-align: center;
	font-weight: bold;
}