.text_one_form input {
	width: 100%;
	height: 46px;
	line-height: 46px;
	padding-left: 10px;
	border: 1px solid #e6e6f4;
	background: #f8f8ff;
}
.text_one_form input::placeholder {
	color: #26226260;
}
.program_labels{
	color: #262262;
	margin-bottom: 0;
}

.akv_part{
	padding-bottom: 20px;
}

.rec_type{
	padding-top: 10px;
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.offer_dates{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.custom_date_picker{
	margin-top: 20px;
	--rmdp-primary-red: #262262;
	--rmdp-secondary-red: #262262;
	--rmdp-shadow-red: #262262;
	--rmdp-today-red: #262262;
	--rmdp-hover-red: #262262;
	--rmdp-deselect-red: #262262;
}

.top_one {
	top: 32px;
	right: 14px;
}

.down_one {
	right: 14px;
	bottom: 6px;
}
.down_three {
	right: 14px;
	bottom: 6px;
	height: 20px;
}

.top_two {
	top: 0;
	right: 14px;
	height: 20px;
}

.down_two {
	right: 14px;
	bottom: 6px;
	height: 20px;;
}

.daily_style {
	border: 1px solid #e6e6f4;
	background: #f8f8ff;
	color: #262262;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 20px;
	margin-left: 0.8rem;
	cursor: pointer;
}

.daily_style_active {
	background: #262262;
	color: #f8f8ff;
}
.text_all_area_pop input {
	width: 300px;
	height: 46px;
	border: 1px solid #e6e6f4;
	background: #f8f8ff;
	line-height: 46px;
	padding-left: 14px;
}
.edit-pen-pop {
	border: 1px solid;
	width: 165px;
	height: 54px;
	line-height: 54px;
}

span.edit-class {
	border-left: none;
	padding-top: 0;
	padding-bottom: 0px;
	padding-left: 0;
}

.pointer{
	cursor: pointer;
}

.grid_display_dates{
	width: 100%;
	display: grid; 
	grid-template-columns: auto auto auto;
  }