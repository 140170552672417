.coach_row_table {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 160px;
	background: #fff;
	margin-bottom: 10px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.coach_row_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
}

.center {
	justify-content: center;
}
.coach_row_row .coach_row_col {
	padding-left: 10px;
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 1px solid #e0e0e0;
	overflow: auto;
}

.coach_row_col.event_head{
	flex-direction: column;
}

.coach_row_col::-webkit-scrollbar {
	height: 7px;
	margin-right: 2px !important;
}
.coach_row_col::-webkit-scrollbar-track {
	background: none;
}
.coach_row_col::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.coach_row_col::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}
.photo_head {
	width: 12%;
	padding-left: 0;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;	
}
.name_head,
.last_head {
	width: 12%;
}
.detail_head {
	width: 26%;
}
.event_head {
	width: 26%;
}
.view_head {
	width: 12%;
}
.coach_img {
	width: 80%;
	
}
.coach_detail_link {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.arrow_div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	transition: all 0.3s;
}
.arrow_div:hover {
	background: #d91c24;
}
