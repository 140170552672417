.coupons_row_table {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 120px;
	background: #fff;
	border-bottom: 1px solid #e0e0e0ab;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}

.coupons_row_row_canceled {
	background-color: #F2F2F2;
}

.coupons_row_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
}

.coupons_row_row .coupons_row_col {
	padding-left: 10px;
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 2px solid #e0e0e0;
	overflow: auto;
	text-transform: uppercase;
}
.coupons_row_col::-webkit-scrollbar {
	width: 7px;
	margin-right: 2px !important;
}
.coupons_row_col::-webkit-scrollbar-track {
	background: none;
}
.coupons_row_col::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.coupons_row_col::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.coupons_row_in::-webkit-scrollbar {
	width: 7px;
	margin-right: 2px !important;
}
.coupons_row_in::-webkit-scrollbar-track {
	background: none;
}
.coupons_row_in::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.coupons_row_in::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.coupon_date_col {
	display: flex !important;
	flex-direction: column;
	justify-content: center !important;
	align-items: flex-start !important;
}
.coupon_detail_link {
	color: #262262;
}
.coupons_edit_link {
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 2px solid #e0e0e0;
	overflow: auto;
	height: 100%;
	justify-content: center;
}
.arrow_div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	transition: all 0.3s;
}
.arrow_div:hover {
	background: #d91c24;
}
.check_box_coupon {
	cursor: pointer;
}
.max_uses{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	width: 100%;
	height: 100%;
}
.max_uses .arrows{
    display: flex;
	flex-direction: row;
	width: 100%;
    justify-content: space-evenly;
	margin-top: auto;
	margin-bottom: -30px;
}

.max_uses .arrows img{
	width: 10px;
	cursor: pointer;
}

.max_uses .save{
	margin-bottom: 5px;
    margin-top: auto;
}

.max_uses .save .edit-class{
    color: #d91c24;
	font-size: 14px;
	cursor: pointer;
}

.max_uses input{
	width: 100%;
    padding: 1rem !important;
    background-color: transparent;
    border: 1px solid #f2f2f2;
    color: #262262 !important;
    font-weight: normal;
	margin-inline-start: 5px;
    margin-inline-end: 15px;
}

.max_uses input:focus{
    border-bottom: 1px solid #d91c24;
}