.popup_Input {
	width: 100%;
	background-color: #f2f2f2;
	border: 1px solid #f2f2f2;
	color: #262262;
	font-weight: normal;
}
.popup_Input_Carousel {
	width: 100%;
	padding: 1rem !important;
	background-color: #f2f2f2;
	border: 1px solid #f2f2f2;
	color: #262262 !important;
	font-weight: normal;
}
.popup_Input_Carousel.popup_Input_CarouselError{
	border:1px solid #d91c24;
}
.popup_Input_Carousel::placeholder{
	color: #262262!important;
}
.popup_Input_Carousel:active::placeholder,
.popup_Input_Carousel:focus::placeholder{
	color: #262262!important;
}

.popup_Input_Carousel:active,
.popup_Input_Carousel:focus {
	border-bottom: 1px solid #d91c24;
	outline: none;
	transition: ease 400ms;
}

textarea.popup_Input_Carousel{
	font-size: 14pt;
}

.popup_Input:active,
.popup_Input:focus {
	border-bottom: 1px solid #d91c24;
	outline: none;
	transition: ease 400ms;
}
.popup_Input::placeholder {
	color: #262262;
}

.add_photo_btn {
	position: relative;
	border: none;
	background-color: transparent;
	padding: 0px;
}
.custom_file_input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.add_photo_label {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: underline;
}
.add_photo_label img {
	margin-right: 8px;
}
.popup_button {
	color: #fff;
	background-color: #262262;
	font-size: 14px;
	width: 211px;
	height: 60px;
	outline: unset;
	border: 0px solid;
	font-style: bold;
	text-transform: uppercase;
	font-weight: 500;
}

.popup_button:hover {
	transition: ease-in 0.8ms;
	background-color: #d91c24;
}

.popup_button_2 {
	color: #fff;
	background-color: #d91c24;
	font-size: 14px;
	width: 211px;
	height: 60px;
	outline: unset;
	border: 0px solid;
	font-style: bold;
	text-transform: uppercase;
	font-weight: 500;
}

.popup_button_2:hover {
	transition: ease-in 0.8ms;
	background-color: #262262;
}
.add_voucher_input{
	background-color: #f2f2f2!important;
	width: 100%;
	height: 72px;
	/* margin: 29px 0px 0px 0px; */
	position: relative;
}
.react-datepicker-wrapper{
	width: 100%;
}
.DeleteCarouselCard_Container{
	font-size:1rem;
}
.add_voucher_input input{
	width: 100%;
	height: 72px;
	border: none;
	font-size: 16px;
	color: #262262;
	padding: 20px;
	background:transparent !important
}
.calImg{
	position: absolute;
	right: 8%;
	top: 50%;
}
.labels{
	color:#262262;
	text-transform: uppercase;
	font-size: 12pt;
	font-weight: bold;
}
.error{
	color: red;
}
.popup_select {
	width: 100%;
    padding: 1rem !important;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: #262262 !important;
    font-weight: normal;
	font-size: 14pt;
}

.popup_calendar {
	position: relative;
    display: inline-block;
    width: 100%;
	font-size: 14pt;
	background-color: #f2f2f2;
}

.popup_calendar input{
	font-size: 14pt;
	background-color: #f2f2f2;
	border: 0px solid;
}

.popup_Input_Number {
	width: 100%;
	background-color: #f2f2f2;
	border: 1px solid #f2f2f2;
	color: #262262;
	font-weight: normal;
	font-size: 14pt;
	padding: 1rem !important;
}