.events_popup_table {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 120px;
	background: #fff;
	border-bottom: 1px solid #e0e0e0ab;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.events_popup_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 12px;
	line-height: 18px;
}
.events_popup_row .events_popup_col {
	padding-left: 10px;
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 2px solid #e0e0e0;
	overflow: auto;
	text-transform: uppercase;
}
.events_popup_col::-webkit-scrollbar {
	width: 7px;
	margin-right: 2px !important;
}
.events_popup_col::-webkit-scrollbar-track {
	background: none;
}
.events_popup_col::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.events_popup_col::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.events_popup_in::-webkit-scrollbar {
	width: 7px;
	margin-right: 2px !important;
}
.events_popup_in::-webkit-scrollbar-track {
	background: none;
}
.events_popup_in::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.events_popup_in::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.event_date_col {
	display: flex !important;
	flex-direction: column;
	justify-content: center !important;
	align-items: flex-start !important;
}

.wi-30 {
	width: 30%;
  }
  
.wi-17 {
width: 17%;
}

.wi-23 {
width: 23%;
}
