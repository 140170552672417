.member_edit-auto-address-textfield{
	width: 100%;
	padding: 1rem !important;
	background-color: #fff !important;
	border: 1px solid #fff !important;
	margin: 0.5rem 0 1rem !important;
	color: #262262;
	font-weight: normal;

	& .MuiInput-underline:before{
        border-bottom: none !important;
    }

	& .MuiInput-underline:after{
		border-bottom:none !important
	}

}
.coach_edit_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}
.left_edit_top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.coach_edit_body {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px;
}
.coach_pfp_div {
	display: flex;
	flex-direction: column;
}
.coach_edit_pfp {
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin-bottom: 40px;
}
.coach_edit_input_date{
	background-color:#fff !important;
	display:flex;
	align-items: center;
}
.coach_edit_input_date input{
	padding: 1rem !important;
	border: 1px solid #fff;
	// margin: 0.5rem 0 1rem;
	color: #262262;
	width:100%;
	font-weight: normal;
	background-color: transparent;
}
.delete_img_btn {
	position: absolute;
	width: 30px;
	height: 30px;
	background: #d91c24;
	right: 5%;
	top: 5%;
	border-radius: 50%;
	cursor: pointer;
}
.coach_pfp_container{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.coach_edit_pfp img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.add_photo_btn {
	position: relative;
	border: none;
	background-color: transparent;
	padding: 0px;
}
.custom_file_input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.add_photo_label {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: underline;
}
.add_photo_label img {
	margin-right: 8px;
}

//form
.coach_edit_form {
	width: 75%;
}
.edit_form_row {
	display: flex;
	flex-direction: row;
}
.coach_edit_input_div {
	margin-right: 20px;
	display: flex;
	flex-direction: column;
	width: 50%;
}
.coach_edit_input {
	width: 100%;
	padding: 1rem !important;
	background-color: #fff;
	border: 1px solid #fff;
	margin: 0.5rem 0 1rem;
	color: #262262;
	font-weight: normal;
}
.coach_edit_input:active,
.coach_edit_input:focus {
	border-bottom: 1px solid #d91c24;
	outline: none;
	transition: ease 400ms;
}
.coach_edit_input::placeholder {
	color: #26226260;
}
.coach_edit_input + label {
	opacity: 0%;
	color: #262262;
	visibility: hidden;
}
.coach_edit_input:focus + label {
	visibility: visible;
	opacity: 100%;
	transition: ease 500ms;
}

//number input
.number_input::-webkit-outer-spin-button,
.number_input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.number_input {
	-moz-appearance: textfield;
}

.edit_button_div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.save_red_button {
	width: 200px;
	border: none;
	background: #d91c24;
	padding: 15px 50px;
	margin-top: 20px;
	margin-right: 15px;
}
.cancel_blue_button {
	text-align: center;
	width: 200px;
	border: none;
	background: #262262;
	padding: 15px 50px;
	margin-top: 20px;
}


.error{
	color: red;
}