.school_input{
    background-color: #e0e0e0 !important;
    color: #262262;
    padding: 10px !important;
}
.add_school_btn_popup{
    padding: 8px;
    font-size: 20px !important;
    width: 30%;
    text-align: center;
    cursor: pointer;
}
.event_edit_input.school_div {
	margin-bottom: 15px;
	display: flex;
	position: relative;
	flex-direction: column;
	width: 32%;
    height:unset;
    background-color:#fff;
	color: #262262;
}

.error{
    color:red;
}
