.reactDatePicker_component input{
	width: 100%;
	border: none;
	font-size: 16px;
    outline: none;
	color: #262262;
	padding: 20px;
	background:transparent !important
}
.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view{
	margin:0 1rem !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
	top:50% !important;
	transform: rotate(135deg) translateY(100%) !important;
	right:-20px !important;
}
.react-datepicker__navigation{
	top: 8px !important;
}
.react-datepicker__navigation--years-upcoming{
	position: relative;
	top:-4px !important;
}
.react-datepicker__navigation--years-upcoming::before{
	content:'';
	position: absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	width:0;
	height:0;
	border-left: 5px solid transparent;
  	border-right: 5px solid transparent;
  	border-bottom: 5px solid black;
}
.react-datepicker__navigation--years-previous{
	position: relative;
	top:4px !important;
}
.react-datepicker__navigation--years-previous::before{
	content:'';
	position: absolute;
	bottom:50%;
	left:50%;
	transform:translate(-50%,50%);
	width:0;
	height:0;
	border-left: 5px solid transparent;
  	border-right: 5px solid transparent;
  	border-top: 5px solid #000;
}