.revoke_access_button{
	border:1px solid #262262;
	padding:.5rem 1rem;
	width:max-content;
	cursor: pointer;
	
}
.coach_detail_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}
.left_detail_top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.go_back_arrow {
	margin-right: 10px;
}
.edit_coach {
	margin-left: 15px;
	padding: 0px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	width: 120px;
	color: #fff;
	background: #262262;
}

.delete_coach {
	margin-left: 15px;
	padding: 0px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	width: 140px;
	color: #fff;
	background: #d91c24;
}
.link_text_div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 100%;
	border-left: 1px solid #514c91;
}

.link_text_div_delete{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 100%;
	border-left: 1px solid #ffffff;
}

//////////////// main body //////////////////
.coach_detail_body {
	padding: 20px;
}
.coach_detail_body .main_coach_info {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.coach_detail_body .main_coach_info .coach_pfp {
	width: 120px;
	height: 120px;
}
.coach_detail_body .main_coach_info .coach_pfp img {
	width: 100%;
}
.coach_detail_body .main_coach_info .coach_pfp_resize img {
	width: 100%;
	height: 120px;
	object-fit: cover;
}
.coach_phone_num,
.coach_email,
.coach_address {
	color: #262262;
}
.coach_phone_num img,
.coach_email img,
.coach_address img {
	margin-right: 10px;
}

/////////////////// EVENTS ///////////////////
.assigned_event_container {
	margin-top: 20px;
	background: #fff;
	height: 270px;
}
.assigned_event_container_title {
	display: flex;
	align-items: center;
	padding-left: 20px;
	height: 40px;
	width: 100%;
	border-bottom: 1px solid #dbdbdb;
}
.coach_event_container {
	height: calc(100% - 40px);
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.coach_events_sliders {
	height: 100%;
	width: 30%;
	border: 1px solid #dbdbdb;
}
.event_slider_title {
	background: #fff;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 100%;
	border-bottom: 1px solid #dbdbdb;
}
.coach_event_main_items {
	height: calc(100% - 40px);
	overflow: hidden auto;
	padding-left: 5px;
}
.coach_event_main_items::-webkit-scrollbar {
	width: 10px;
	margin-right: 2px !important;
}
.coach_event_main_items::-webkit-scrollbar-track {
	background: none;
}
.coach_event_main_items::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: #e7e7ef;
}
.coach_event_main_items::-webkit-scrollbar-thumb:hover {
	background: #dcdcec;
}

.radio_btn {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.radio_btn label {
	margin: 3px 0px 0px 5px;
}
.access_red_button {
	border: none;
	background: #d91c24;
	padding: 15px 50px;
	margin-top: 20px;
}

.certificate{
	background: #fff;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
	width: 50%;
	padding: 10px;

	&__div{
		width: 100%;
		height: 100%;
		border: 1px solid #d91c24;
		padding: 10px;
		color: #262262;
	}
}
