.HomeCarouselCard_Container{
    position: relative;
    border: 2px solid #262262;
    padding: 1rem;
    margin:1rem 0;
}

.HomeCarouselCard_Remove{
    position:absolute;
    top:1rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d91c24;
    right:1rem;
    cursor: pointer;
}

.HomeCarouselCard_Edit{
    position:absolute;
    bottom:1rem;
    right:1rem;
    display:flex;
}

.HomeCarouselCard_EditButton{
    background-color: #262262;
    color:#fff;
    cursor: pointer;
    padding:.5rem;
}

.HomeCarouselCard_Image{
    object-fit: cover;
    width:70%;
    height:150px;   
}