.auto-address-textfield{
    width: 100% !important;
    // height: 60px !important;
    background: #F4F4F4;
    border: none !important;
    position: relative !important;

    & div{
        height: 100%;
        border: none !important;
        outline: none !important;

        & fieldset{
            border-bottom: none !important;
            border-radius: 0 !important;
            // border-bottom: 1px solid #8e8e8e;
        }
    }

    .address-icon{
        color: #79769F;
        font-size: 24px;
    }

    .address-auto-container {
        color: #79769F;
        background: #fff;
        padding: 10px;
        height: 175px;
        font-size: 13px;
        width: 100%;
        position: relative;

        & .MuiPaper-root{
            position: absolute !important;
            z-index: 99;
        }
    };

    .auto-address-textfield{
        width: 100% !important;
        height: 60px !important;
        background: #F4F4F4;
        border: none !important;
        position: relative !important;

        & div{
            height: 100%;
            border: none !important;
            outline: none !important;

            & fieldset{
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #8e8e8e;
            }
        }

        .address-icon{
            color: #79769F;
            font-size: 24px;
        }

        .address-auto-container {
            color: #79769F;
            background: #fff;
            padding: 10px;
            height: 175px;
            font-size: 13px;
            width: 100%;
            position: relative;

            & .MuiPaper-root{
                position: absolute !important;
                z-index: 99;
            }
        };

    }
    
}

.states_shipping{
    width: 100%;
    padding: 1rem !important;
    background-color: #fff !important;
    border: 1px solid #fff !important;
    margin: 0.5rem 0 1rem !important;
    color: #262262;
    font-weight: normal;
    &:focus{
        outline: none;
    }

}

.member_edit-auto-address-textfield{
	width: 100%;
	padding: 1rem !important;
	background-color: #fff !important;
	border: 1px solid #fff !important;
	margin: 0.5rem 0 1rem !important;
	color: #262262;
	font-weight: normal;

	& .MuiInput-underline:before{
        border-bottom: none !important;
    }

	& .MuiInput-underline:after{
		border-bottom:none !important
	}
}

.coach_edit_input {
	width: 100%;
	padding: 1rem !important;
	background-color: #fff;
	border: 1px solid #fff;
	margin: 0.5rem 0 1rem;
	color: #262262;
	font-weight: normal;
}
.coach_edit_input:disabled{
    color: gray;
}
.coach_edit_input:active,
.coach_edit_input:focus {
	border-bottom: 1px solid #d91c24;
	outline: none;
	transition: ease 400ms;
}
.coach_edit_input::placeholder {
	color: #26226260;
}
.coach_edit_input + label {
	opacity: 0%;
	color: #262262;
	visibility: hidden;
}
.coach_edit_input:focus + label {
	visibility: visible;
	opacity: 100%;
	transition: ease 500ms;
}