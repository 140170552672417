.forgot-password {
  .center_part_container {
    width: 75%;
  }
  .center_part{margin-top:150px!important;}
  .text_one_form input {
      width: 461px;
      height: 60px;
      border: 1px solid #3E3A73;
      background: #3E3A73;
      line-height: 60px;
      padding-left: 24px;
  }
  .button_edit {
      height: 60px;
      line-height: 60px;
      text-align: center;
      width: 461px !important;
      display: block;
  }
  .form_part {
      border-left: 1px solid #514C91;
      padding-left: 37px;
    padding-bottom: 20px;
  }
  .login_logo {
      position: absolute;
      top: -80px;
  }
  .login_logo img{width:70px;height:auto;}
  .login_logo {
      position: absolute;
      top: -188px;
      left: -80px;
  }
  .no1draft_logo {
    width: 50%;
    text-align: center;
   }
   .form_part {
    width: 50%;
    padding-top: 25px;
    padding-bottom: 25px;
   }
  .no1draft_logo {
    > div {
      display: table;
      margin: 0 auto;
      position: relative;
    }
 }
 .white::placeholder {
    color: white;
    opacity: 1;
 }
}



@media (max-width: 1500px) {
  .forgot-password .center_part_container {
    width: 92%;
  }
}
@media (max-width: 1230px) {
  .forgot-password {
    .center_part_container {
      width: 100%;
    }    
  }
}
@media (max-width: 1024px) {
  .forgot-password {
    .center_part_container.d-flex.justify-content-between.align-items-center{
      display:block !important;
    }
    .no1draft_logo {
      display: block !important;
      width: 100%;
    }
    .form_part {
      display: block !important;
      border-left: none;
      padding-left: 0;
      padding-bottom: 0;
      margin-top: 70px
    }
    .login_logo img{
      width: 60px !important;
    }
  }
}
@media (max-width: 580px) {
  .forgot-password {
    .form_part {
      margin-top: 0px;
      width: 100% !important;
    }
    .center_part {
      margin-top: 96px!important;
    }
    .no1draft_logo img{
      max-width:100% !important;
    }
    .text_one_form input {
      width: 100%;
    }
    // .form_title {
    //   font-size: 17px !important;
    // }
    .form_style img{
      width:30px !important;
    }
    .login_logo img{
      width:45px !important;
    }
    .login_logo {
      top: -76px;
      left: 0;
    }
    .no1draft_logo {
      text-align: left !important;
      width:100% !important;
    }
    .button_edit {
      width: 100% !important;
      // display: block;
    }
    .form_title.font-36 {
      font-size: 22px;
    }
  }
}
