.noticeboard_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}

.noticeboard_form_div {
	height: 100%;
	display: flex;
	flex-direction: row;
}
.noticeboard_add_new {
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 120px;
	color: #fff;
	background: #262262;
}
.no_item_available {
	font-weight: bold !important;
	font-size: 40px !important;
	color: #262262 !important;
}
@media (max-width: 620px) {
	.coach_top_div {
		flex-direction: column;
	}
}
@media (max-width: 410px) {
	.coach_top_div {
		padding: 0px;
	}
	.coach_form_div {
		flex-direction: column;
		align-items: flex-end;
	}
	.coach_add_new {
		margin-left: 0px;
		padding: 5px;
	}
}
