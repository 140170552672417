.create-post a span {
  font-size: 30px;
  line-height: 18px;
  display: inline-block;
  margin-right: 10px;
}
.title-bar {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 25px;
}
.size_selector{
  height: 60px !important;
  .css-yk16xz-control, .css-1pahdxg-control{
    height: 100% !important;
  }
}

.post_details.no-image .post__details {
  width: 100%;
}
body {
  background: #f8f8ff !important;
}
.post__details {
  min-height: 90px;
  padding: 15px 15px 0px;
  position: relative;
}

.recently-added .post__image {
  width: 200px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.underline {
  text-decoration: underline;
}
.post-image {
  width: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.post_time.font-11 {
  position: absolute;
  bottom: 10px;
}
.recently-added .post_details {
  background: rgba(81, 76, 145, 0.1);
  min-height: 120px;
}
.font-36 {
  font-size: 36px;
}
.font-14 {
  font-size: 14px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.padding-right-20 {
  padding-right: 20px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-30 {
  padding-left: 30px;
}
.edit-pen {
  border: 1px solid;
  width: 160px;
  height: 54px;
  line-height: 54px;
}
span.edit-class {
  border-left: none;
  padding-top: 0;
  padding-bottom: 0px;
  padding-left: 0;
}
.edit-pen-img img {
  vertical-align: top;
  padding-top: 0px;
  padding-right: 0px !important;
}
.padding-left-10 {
  padding-left: 10px;
}
.header_top_container {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 20px;
}
.danny_profile img {
  width: 169px;
  height: auto;
}
.edit-plus-img img {
  vertical-align: top;
  padding-top: 17px;
}
.search-div.search-div-two {
  background: #514c91;
  height: 54px;
  line-height: 54px;
  text-align: center;
}
.search-div.search-div-two input[type="submit"] {
  position: absolute;
  left: 0;
  background: url(../../assets//images/Search-white.svg) no-repeat left center;
  color: transparent;
  border: none;
  top: 13px;
  width: 20px;
  cursor: pointer;
  height: 30px;
  background-size: 100%;
}
.search-field.search-field-two input[type="search"] {
  background: transparent;
  border: none;
  color: #fff !important;
  padding: 0px 0px 0px 45px;
  line-height: 57px;
}
.company-table.ompany-table-new thead tr th {
  background: #262262;
  color: #fff;
  font-family: bold;
  padding: 20px 25px;
  font-size: 14px;
  border-right: 1px solid #eff3f9;
  text-transform: uppercase;
}
.pending_logo img {
  width: 135px;
}
.logo_arrow img {
  width: 12px;
  height: auto;
}
.input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}
.input-container.input_container_new.input-container_two
  input:checked
  ~ .checkmark {
  background-color: #d91c24;
  border: none;
}
label.input-container.input_container_new.input-container_two .checkmark:after {
  left: 8px;
  top: 9px;
  width: 10px;
  height: 10px;
  background: url(../../assets//images/white-tick.svg) no-repeat;
  background-size: 100%;
}
span.checkmark.checkmark_new {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
}
.input-container input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  content: "";
  position: absolute;
}
.pending_logoo img {
  width: 67px;
  height: auto;
}
.pending_logo_arrow img {
  width: 12px;
  height: auto;
}
.company-table thead tr th:first-child {
  border-top-left-radius: 20px;
}
.company-table tbody tr {
  border-bottom: 1px solid #eff3f9;
}
.company-table.ompany-table-new tbody tr td {
  padding: 30px 25px;
  background: #fff;
  border-right: 1px solid #eff3f9;
  font-size: 18px;
  font-family: "regular";
  color: #262262;
}
label.input-container.input_container_new {
  margin-bottom: 24px;
}
.input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.qty_input_store{
  width: 80% !important;
  &::placeholder{
    color: #26226260;
  }
}
.qty_input_store::-webkit-outer-spin-button,
.qty_input_store::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.qty_input_store {
  -moz-appearance: textfield;
}



span.checkmark.checkmark_new {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #abadc1;
  border-radius: 5px;
}
span.pending_logo_arrow_footer {
  vertical-align: unset;
}
.size_picker{
  position: relative;
}
.delete_size_store{
  background: #d91c24;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20%;
  right: 30%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  img{
    width: 25px;
  }
}

.text_one_area input {
  width: 461px;
  height: 72px;
  border: 1px solid #fff;
  background: #fff;
  line-height: 72px;
  padding-left: 24px;
  display: block;
}
.vertical-align-super {
  vertical-align: super;
}
.text_all_area input {
  width: 100%;
  height: 72px;
  border: 1px solid #fff;
  background: #fff;
  line-height: 72px;
  padding-left: 24px;
}
.text_all_area input:focus {
  border-bottom: 2px solid #d91c24 !important;
}
.text_one_area input:focus {
  border-bottom: 2px solid #d91c24 !important;
}
.new_prod {
  width: 480px;
}
.icon_products {
  top: 51px;
  right: 43px;
}
.icon_products img {
  width: 12px;
  height: auto;
}
.active_input {
  border-bottom: 2px solid #d91c24;
}
span.date_pick.position-absolute {
  top: 96px;
  right: 28px;
}
.transparent_color {
  color: #f8f8ff !important;
}

.add_size_product_table{
  width: 500px;

  thead tr td{
    background: #262262;
    border: 1px solid #8b8b8b;
    color: #fff;
    font-weight: bold;
    padding: 20px;
  }

  tbody tr td{
    color: #262262;
    border: 1px solid #8b8b8b;
    font-weight: bold;
    padding: 10px;
  }
}

@media (max-width: 1700px) {
  .text_all_area.responsive input {
    width: 93% !important;
  }
  .parent.text-upper.bold.font-14 {
    display: block;
  }
}

@media (max-width: 1200px) {
  .w-100.d-flex.justify-content-between.align-items-center.pt-2 {
    display: block !important;
  }
  .text_all_area.responsive input {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .text_all_area.d-inline-block.pt-5.blue.regular.font-16 {
    width: 100%;
  }
  .pt-2.d-inline-block.stocks {
    width: 100%;
  }
  .new_prod {
    width: 100%;
  }
  .text_one_area input {
    width: 100%;
  }
  .text_one_area.position-relative.blue.regular.font-16 {
    padding-right: 20px;
  }
  .text_all_area.pt-5.blue.regular.font-16 {
    padding-right: 20px;
  }
  .d-inline-block.pl-2 {
    display: block !important;
    width: 100%;
    padding-top: 10px;
  }
  .header_title_left.bold.blue.font-36.float-left.text-upper {
    font-size: 20px;
    padding-left: 17px;
  }
}
.phone_number {
  position: relative;
  display: inline-block;
  a {
    display: inline-block;
    color: #262262;
    padding-top: 8px;
    text-decoration: underline;
  }
  span {
    display: inline-block;
    color: #262262;
    padding-top: 8px;
    text-decoration: underline;
  }
  input {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
  }
}

.error{
  color: red;
}
