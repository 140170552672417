.coupons_top_div {
	display: flex;
	height: 20%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px 20px 20px;
	border-bottom: 1px solid #dbdbdb;
}
.coupons_form_div {
	padding-left: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.dropDown_bar select {
	padding: 10px 5px;
	border: none;
	width: 185px;
	color: #fff;
	font-size: 17px;
	background: #514c91;
	height: 100%;
}

.dropDown_bar_status select {
	padding: 10px 5px;
	border: none;
	width: 120px;
	color: #fff;
	font-size: 17px;
	background: #514c91;
	height: 100%;
}
.plus-image{
	padding: 0 5px 7px 5px;
}
.coupon_add_new {
	display: flex;
	align-items: center;
	width: 110px;
	color: #fff;
	background: #262262;
}

.category_add_new {
	display: flex;
	padding: 2px;
	align-items: center;
	width: 150px;
	color: #fff;
	background: #262262;
}
/*///////////////////MAIN BODY///////////// */
.coupons_list_main_body {
	width: 100%;
	height: 60%;
	padding: 20px;
}
.coupons_list_top {
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	background: #262262;
}
.coupons_table_header {
	display: flex;
	align-items: center;
	height: 100%;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	border-left: 2px solid #514c91;
	padding: 10px;
}
.wi-16 {
	width: 16%;
}
.wi-13 {
	width: 13%;
}
.wi-10 {
	width: 10%;
}
.wi-8 {
	width: 8%;
}
.wi-5 {
	width: 5%;
}


.coupon_deactivate_btn {
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 160px;
	background: #d91c24;
	cursor: pointer;
}
.coupon_deactivate_btn_disable {
	margin-left: 15px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 160px;
	background: gray;
	cursor: pointer;
}