.event_program_card {
	background: #fff;
	width: 200px;
	height: 200px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.edit_card_div {
	width: 100%;
	height: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.card_info {
	width: 100%;
	height: 180px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.eventProgramCard_icons{
	fill:#686692;
}
.eventProgramCard_icons:hover{
	fill:#262626;
}