.reset-password {
  .center_part_container .all_align {
    // width: 75%;
  }
  .no1draft_logo {
    margin-bottom: 150px;
  }
  .text_one_form input {
    width: 100%;
    height: 72px;
    border: 1px solid #3E3A73;
    background: #3E3A73;
    line-height: 72px;
    padding-left: 24px;
    margin-right: 20px;
  }
  .button_edit {
    width: 100%;
    height: 72px;
    line-height: 72px;
    text-align: center;
  }
  .row-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .row-10 > div[class*="col"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  
  @media (max-width: 1570px) {
    .button_edit {
      width: 352px!important;
      height: 60px;
      line-height: 60px;
    }
    .text_one_form input {
      width: 352px!important;
      height: 60px;
      line-height: 60px;
    }
  }
  @media (max-width: 1200px) {
    .center_part_container .all_align{
      display:block!important;
    }
  }
  @media (max-width: 580px) {
    .form_part {
      margin-top: 20px;
    }
    .no1draft_logo img{
      width:230px!important;
    }
    .text_one_form input {
      width: 100%!important;
    }
    .form_title {
      font-size: 17px!important;
    }
    .form_style img{
      width:30px!important;
    }
    .button_edit {
      width: 100%!important;
    }
    .no1draft_logo {
      margin-bottom: 30px;
    }
    .container{
      padding-left: 0;
      padding-right: 0;
    }
  }
}
