.member_main_details {
	margin-top: 70px;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
.member_account_container {
	background: #fff;
	height: 280px;
	width: 48%;
}
.member_register_detail_container {
	background: #fff;
	height: 280px;
	width: 55%;
}
.address_container{
	white-space: nowrap;
}
.address_text{
	line-height: 1.5em;
    height: 3em;     
    overflow: hidden;
}
.member_container_title {
	display: flex;
	align-items: center;
	padding: 20px;
	height: 60px;
	width: 100%;
	border-bottom: 1px solid #dbdbdb;
}
.member_detail_container {
	height: calc(100% - 60px);
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.members_info_container {
	height: calc(100% - 60px);
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}
.add_child_btn {
	background: #d91c24;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	cursor: pointer;
}
.add_child_btn img {
	width: 60%;
}
.left_account_div,
.right_account_div {
	display: flex;
	flex-direction: column;
	/* align-items: flex-start; */
	justify-content: space-between;
	width: 46%;
	height: 100%;
}
.member_detail_div {
	display: flex;
	flex-direction: column;
}
.blue_link_btn {
	width: 90%;
	height: 50px;
	background: #262262;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.blue_kids_link_btn {
	width: 35%;
	height: 50px;
	background: #262262;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.register_detail_top_row {
	width: 100%;
	height: 50%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.kids_detail_row {
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.member_emergency{
	/* display: inline-block; */
	padding-left:15px;
	padding-top:10px;
	width: 100%;
	height: 110px;
	border: 5px solid red;
}

.member_pfp{
	min-width:200px;
	padding-top:15px;
}
