.posts-section {
	display: flex;
	flex-direction: column;
	/* height: 250px; */
}
.post-container {
	display: flex;
	flex-direction: row;
}
.main-dash-detail {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}
.members-section,
.account-section {
	margin-left: 8px;
	margin-right: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
	background: #fff;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}
.member-header-div,
.account-header-div,
.event-metric-header-div {
	padding: 10px 25px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #dbdbdb;
}
.time-selector {
	border: none;
	color: #262262;
	width: 200px;
}
.member-data-div,
.account-data-div {
	padding: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.member-numbers-container,
.account-numbers-container {
	width: 50%;
	padding-top: 10px;
	padding-left: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
/* //////////////////members//////////////////////// */
.member-number-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 40px;
}
.filters_container{
	position: relative;
 	display: flex;
	float:right;
}
.members-update-circle-div {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
/* //////////////////account//////////////////////// */
.event-numbers,
.sales-numbers {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
/* //////////////////EVENTS METRIC//////////////////////// */
.events-metric {
	margin-top: 30px;
	background: #fff;
	width: 100%;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.event-metric-data-container {
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.metric-numbered-data {
	height: 300px;
	/* max-width: 100px; */
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.metric-data-circles {
	display: flex;
	flex-direction: column;
	/* justify-content: space-evenly; */
}
.metric-data-legend {
	display: flex;
	flex-direction: row;
	/* justify-content: space-evenly; */
}
