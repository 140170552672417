.member_img {
	padding-left: 0px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.check_col {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 0px !important;
}
.check_box_member {
	cursor: pointer;
}
.order_row_col {
	padding-left: 10px;
	display: flex;
	align-items: center;
	height: 100%;
	color: #262262;
	border-left: 1px solid #e0e0e0;
	overflow: auto;
	font-size: 14px;
}