.login {
  .center_part {
    margin: auto;
    margin-top: 60px;
  }
  .center_part_container {
    width: 75%;
  }
  .text_one_form input {
    width: 461px;
    height: 62px;
    border: 1px solid #3E3A73;
    background: #3E3A73;
    line-height: 62px;
    padding-left: 24px;
  }
  .button_edit {
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 461px !important;
    display: block;
  }
  .form_part {
    border-left: 1px solid #514C91;
    padding-left: 37px;
    padding-bottom: 20px;
  }
  .no1draft_logo {
    width: 50%;
    text-align: center;
   }
   .form_part {
    width: 50%;
    padding-top: 25px;
    padding-bottom: 25px;
   }

   .white::placeholder {
    color: white;
    opacity: 1;
  }

  .error{
    color:red;
  }
}





@media (max-width: 1500px) {
  .login .center_part_container {
    width: 92%;
  }
}
@media (max-width: 1230px) {
  .login {
    .center_part_container {
      width: 100%;
    }
  }
}
@media (max-width: 1024px) {
  .login {
    .center_part_container.d-flex.justify-content-between.align-items-center{
      display:block !important;
    }
    .no1draft_logo {
      display: block !important;
      width: 100%;
    }
    .form_part {
      display: block !important;
      border-left: none;
      padding-left: 0;
      padding-bottom: 0;
      margin-top: 70px
    }
  }
}
@media (max-width: 580px) {
  .login {
    .form_part {
      margin-top: 20px;
      width: 100% !important;
    }
    .no1draft_logo img{
      max-width:100% !important;
    }
    .text_one_form input {
      width: 100%;
    }
    .form_title {
      font-size: 20px !important;
    }
    .form_style img{
      width:40px !important;
    }
    .no1draft_logo {
      text-align: left !important;
      width:100% !important;
    }
    .button_edit {
      width: 100% !important;
      // display: block;
    }
  }
}